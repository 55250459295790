import About from "./about"


function Main(){

    return(
        <>
        
        {/* <div className="logo-name">Benjamin Lau is currently redoing his portfolio website from scratch. Last updated April 9, 2023</div> */}


        </>
    )
}



export default Main